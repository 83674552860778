import axios from "axios";
import { createContext, useEffect, useState } from "react";
import Cookies from 'universal-cookie';

axios.defaults.withCredentials = true;
axios.defaults.baseURL = 'https://travel-app-web-server-dibyashu-kashyaps-projects.vercel.app/api';

export const UserContext = createContext ({});
const cookies = new Cookies(null, { path: '/' });

// cookies.set('myCat', 'Pacman');
// console.log(cookies.get('myCat')); // Pacman
// cookies.set('token', 'Pacman');
// console.log(cookies.get('token')); // Pacman

export function UserContextProvider({ children }) {
    const [user,setUser] = useState(null);
    const [ready,setReady] = useState(false);
    const [isPartner,setIsPartner] = useState(false);
    const setTokenAndCookie = async(newToken) => {
        // Set the token in the UserContext
        // setToken(newToken);
    
        // Set the token in a cookie
        console.log('in setTokenAndCookie');
        cookies.set('token', newToken);
      };
      const getCookie = async( ) => {
        // Set the token in the UserContext
        // setToken(newToken);
    
        // Set the token in a cookie
        return cookies.get('token');
        // cookies.set('token', newToken);
      };

      const getAuthHeaders = () => {
        // Return headers with the Authorization token
        return {
          headers: {
            // Authorization: `Bearer ${token}`,
            Authorization: `Bearer ${cookies.get('token')}`,
          },
        };
      };

    useEffect(() => {
        // if (!user) {
        //     axios.get('/profile').then(({data}) => {
        //         setUser(data);
        //         // localStorage.setItem('token', data.token);
        //         setReady(true);
        //     });
        // }
        if (!user) {
            // axios.get('/profile')
        //    const token = localStorage.getItem('token');
             axios.get('/profile', {
             headers: {
            //  Authorization: `Bearer ${token}`,
            Authorization: `Bearer ${cookies.get('token')}`,

        },
                    })
             .then(({data}) => {
                 setUser(data);
                //  console.log("token in usercontext",cookies.get('token'));
                 setReady(true);
             });
         }
    }, []);
    return (
        <UserContext.Provider value={{user,setUser,
        ready,setTokenAndCookie,setIsPartner,isPartner,
        getCookie,getAuthHeaders}}>
            {children}
        </UserContext.Provider>
    );
}
