import React, { useState ,useContext} from "react";
import { Link, Navigate } from "react-router-dom";
import axios from "axios";
import { UserContext } from "./UserContext.jsx";


export default function ClientLoginPg() {
  const [emailOrPhone, setEmailOrPhone] = useState('');
  const [password, setPassword] = useState('');
  const [redirect, setRedirect] = useState(false);
  const apiBase='https://work-manage-server.vercel.app';

  const { setUser,setTokenAndCookie,getCookie,setIsPartner } = useContext(UserContext);


  // async function handleLoginSubmit(ev) {
  //   ev.preventDefault();
  //   try {
  //     // Your authentication API endpoint (replace '/login' with the actual endpoint)
  //     const { data, token } = await axios.post('/login', { emailOrPhone, password });

  //     // Assuming you have a UserContext to store user information
  //     // const { setUser } = useContext(UserContext);
  //     // setUser(data);

  //     alert('Login successful');
  //     setRedirect(true);
  //   } catch (e) {
  //     alert('Login failed');
  //   }
  // }
  async function handleLoginSubmit(ev) {
    ev.preventDefault();
    try {
      // console.log("Login called atleast");
      const { data } = await axios.post(`${apiBase}/login`, {
        //  email
          password 
         });

         if(data)console.log(data);
         if(data?.token)
         {
          const { token } = data;

          // Set the token in the user context
          setTokenAndCookie(token);
    
       alert('Login successful');
       setRedirect(true);
         }
         else
         alert('Login Fail');
    } catch (e) {
      console.log(e);
      alert('Login failed');
    }
  }

  if (redirect) {
    return <Navigate to={'/:projectId'} />;
  }

  return (
    <div className="flex flex-col md:flex-row items-center justify-center lg:mt-20 text-white lg:ml-20 lg:mr-20">
      {/* Image Section (for large screens) */}
      <div className="hidden md:block md:w-1/2 lg:ml-10 lg:mr-10">
        <img
          src="https://i.ibb.co/tqFDc1W/Image-13-08-23-at-5-07-PM.jpg"
          alt="Profile"
          className="w-160 h-100 rounded-full mb-4"
        />
      </div>

      {/* Right side - Form */}
      <div className="md:w-1/2 bg-secondry  p-8 mt-10">
        <h1 className="text-2xl md:text-4xl text-center rounded-full bg-secondary py-3 mb-6">Login to your account</h1>
        
        <form className="max-w-md lg: mt-20 mx-auto" onSubmit={handleLoginSubmit}>
          {/* <input
            type="text"
            placeholder="Your Email or Phone"
            value={emailOrPhone}
            onChange={(ev) => setEmailOrPhone(ev.target.value)}
            className="w-full mb-2 p-2"
          /> */}
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(ev) => setPassword(ev.target.value)}
            className="w-full mb-5 p-2"
          />
          <button type="submit" className="bg-red-500 rounded-lg py-3 w-full mb-5 mt-7">Login</button>
          
          {/* <div className="text-center py-2 text-gray-500 text-sm">
            <Link className="underline text-white" to={'/register'}>
              Don't have an account yet? Register now
            </Link>
          </div> */}

          {/* <div className="text-center py-2 text-gray-500 text-sm">
            Don't have an account yet?{' '}
            <Link className="underline" to={'/loginClient'}>
              Register now
            </Link>
          </div>
          
          <div className="text-center py-2 text-gray-500 text-sm">
            <Link className="underline  " to={'/partner/forgot-password'}>
              Forgot your password?
            </Link>
          </div> */}
        </form>
      </div>
    </div>
  );
}
