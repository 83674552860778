import React from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from './Navbar';
import Sidebar from './Sidebar';

const AppLayout = ({ children }) => {
    // Get the current location using the useLocation hook
    const location = useLocation();

    // Check if the current path is '/login'
    const isLoginPage = location.pathname === '/';

    // Render the sidebar only if it's not the login page
    const renderSidebar = !isLoginPage ? (
        <div className="w-[220px]">
            <Sidebar />
        </div>
    ) : null;

    return (
        <div className='bg-white'>
            <Navbar />
            <div className='w-screen flex container mx-auto' style={{ height: 'calc(100vh - 56px)' }}>
                {renderSidebar}
                <div className="flex-1">
                    <div className="flex">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AppLayout;

